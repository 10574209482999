import {Dispatch, SetStateAction, FC, PropsWithChildren, useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Spinner} from '@hy-vee/web-core';
import styled from 'styled-components';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import {AddIcon, SettingsIcon} from '@hy-vee/icons';
import {colors, breakpoints, sizing} from '@hy-vee/themes';
import Alert from '@hy-vee/web-core/lib/components/alert';

import {StyledIconContainer} from 'client/views/components/store-selection/styles';
import ReservationStepperStoreSelectionResult from 'client/views/components/reservation-stepper/reservation-stepper-store-selection-result';
import {getDeliveryAddressesByCustomerId as getDeliveryAddressesByCustomerIdFetch} from 'client/graphql/queries/delivery-address-queries';
import {ReservationViewType} from 'client/views/reservation-drawer';
import {getCityStateZip} from 'client/utils/view-helpers/address-view-helpers';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {
    getDeliveryAddressesByCustomerId_deliveryAddresses,
    getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations,
    getDeliveryAddressesByCustomerId,
    getDeliveryAddressesByCustomerIdVariables
} from 'autogen/getDeliveryAddressesByCustomerId';
import {ACCOUNT_ADDRESSES_PATH} from 'client/enums/rerouting-paths';
import {FeatureEnabled} from 'client/context/feature-toggle/types';
import {getBasketContinuitySummaryDrawer} from 'client/services/basket-continuity-service';
import {BasketContinuityCartInput, FulfillmentType, LocationEcommerceStatus} from 'autogen/globalTypes';
import {useUserDetails} from 'client/context/user-details';
import AlertStatusType from 'client/enums/alert-status-types';
import {ADDRESS_VERIFICATION} from 'client/enums/feature-toggle-names';
import {
    useAddressValidation,
    TAddressSuggestionWithDeliveryEligibility
} from 'client/hooks/use-address-validation/use-address-validation';
import {locationIsIneligible} from 'client/utils/view-helpers/fulfillment-view-helpers';

import {BasketContinuityLoadingSpinner} from '../../components/reservation-drawer-bc-loading';
import {AddressAddedStatus, AddressFormData, AddressSavedStatus} from '../add-address/AddAddressUtils';

export const addressSavedMessages = {
    SAVED: 'Your address was saved',
    SAVED_NOT_ELIGIBLE: 'Your address was saved, but it is not eligible for delivery'
};

export const changeAddressMessages = {
    ONLY_DISPLAY_ELIGIBLE: '*Only addresses that are eligible for delivery are displayed.'
};

export const buttonText = {
    addAddress: 'Add address',
    manageAddresses: 'Manage addresses'
};

const themeObject = {
    primary: 'black',
    primaryHover: colors.mediumRed
};

const ChangeAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
    background-color: ${colors.grey[100]};
    box-shadow: 0px -2px 23px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 16px;
    @media screen and (min-width: ${breakpoints.small}) {
        padding: 24px;
    }
`;

const SpinnerContainer = styled.div`
    padding-top: 2rem;
    display: block;
    height: 70px;
    position: relative;
    width: 70px;
    margin: 0 auto;
`;

const EligibleAddressText = styled.p`
    margin: 1rem 0;
    padding-left: 1rem;
    font-size: ${sizing[14]};
`;

const fullName = ({firstName, lastName}: getDeliveryAddressesByCustomerId_deliveryAddresses) =>
    !lastName ? firstName : `${firstName} ${lastName}`;

const isShopInStoreOnly = (
    featureEnabled: FeatureEnabled,
    address: getDeliveryAddressesByCustomerId_deliveryAddresses
): boolean => {
    if (!address?.fulfillmentLocations || address.fulfillmentLocations.length === 0) {
        return true;
    }

    const fulfillmentStoreLocation = address.fulfillmentLocations[0].fulfillmentStoreLocation;

    return !fulfillmentStoreLocation || locationIsIneligible(fulfillmentStoreLocation.ecommerceStatus);
};

export const filterDeliveryFulfillmentLocations = (address: getDeliveryAddressesByCustomerId_deliveryAddresses) => {
    let eligibleFulfillmentLocations = address.fulfillmentLocations;

    if (address.fulfillmentLocations && address.fulfillmentLocations.length > 1) {
        eligibleFulfillmentLocations = address.fulfillmentLocations.filter(
            (location) =>
                location.fulfillmentStoreLocation?.ecommerceStatus === LocationEcommerceStatus.ACTIVE ||
                location.fulfillmentStoreLocation?.ecommerceStatus === LocationEcommerceStatus.DELIVERY_ONLY
        );
    }

    return eligibleFulfillmentLocations;
};

export interface IChangeAddressProps {
    basketContinuityInput: BasketContinuityCartInput;
    cartId: string | null;
    currentDeliveryAddress: getDeliveryAddressesByCustomerId_deliveryAddresses | null;
    setDeliveryStores: Dispatch<
        SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations[] | null>
    >;
    setView: Dispatch<SetStateAction<ReservationViewType>>;
    setBasketContinuityInput: Dispatch<SetStateAction<BasketContinuityCartInput | any>>;
    setDeliveryAddress: Dispatch<SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses>>;
    setUserInputAddress: Dispatch<SetStateAction<AddressFormData>>;
    setFulfillmentType: Dispatch<SetStateAction<FulfillmentType>>;
    setIsUserInputAddressEligibleForDelivery: Dispatch<SetStateAction<boolean>>;
    setIsVerified: Dispatch<SetStateAction<boolean>>;
    setAddressSuggestion: Dispatch<SetStateAction<TAddressSuggestionWithDeliveryEligibility[]>>;
    closeDrawer: () => void;
    setStore: Dispatch<SetStateAction<any>>;
    setFulfillmentLocationId: Dispatch<SetStateAction<number | null>>;
    newAddressAddedStatus: AddressAddedStatus;
}

export const ChangeAddress: FC<PropsWithChildren<PropsWithChildren<IChangeAddressProps>>> = ({
    basketContinuityInput,
    cartId,
    setStore,
    setFulfillmentLocationId,
    closeDrawer,
    setDeliveryAddress,
    setUserInputAddress,
    setFulfillmentType,
    setIsUserInputAddressEligibleForDelivery,
    setIsVerified,
    setAddressSuggestion,
    setBasketContinuityInput,
    currentDeliveryAddress,
    setDeliveryStores,
    setView,
    newAddressAddedStatus
}) => {
    const [isBCLoading, setIsBCLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {customerId} = useUserDetails();
    const {featureEnabled} = useFeatureToggle();
    const isAddressVerificationEnabled = featureEnabled(ADDRESS_VERIFICATION);

    const {
        fetchAddressSuggestions,
        updateDeliveryAddress,
        shouldRunVerificationService,
        shouldUpdateDeliveryAddressService,
        shouldRouteToConfirmAddressService,
        isUserInputAddressEligibleForDelivery,
        isAddressVerified,
        getAddressSuggestions
    } = useAddressValidation();

    const {
        data: deliveryAddresses,
        loading: queryLoading,
        refetch
    } = useQuery<getDeliveryAddressesByCustomerId, getDeliveryAddressesByCustomerIdVariables>(
        getDeliveryAddressesByCustomerIdFetch,
        {
            errorPolicy: 'all',
            skip: !customerId,
            variables: {
                customerId: customerId as number
            }
        }
    );

    const handleBasketContinuity = async (
        address: getDeliveryAddressesByCustomerId_deliveryAddresses,
        keepOpen = false
    ) => {
        setIsBCLoading(true);
        setIsLoading(false);
        setFulfillmentType(FulfillmentType.DELIVERY);
        await getBasketContinuitySummaryDrawer({
            ...basketContinuityInput,
            cartId,
            closeDrawer,
            customerId,
            deliveryAddressId: Number(address.deliveryAddressId),
            fulfillmentLocationId: Number(address.fulfillmentLocations![0].fulfillmentLocationId),
            fulfillmentType: FulfillmentType.DELIVERY,
            keepOpen,
            setActiveView: setView,
            setBasketContinuityInput,
            storeId: Number(address.fulfillmentLocations![0].fulfillmentStoreId)
        });
        setIsBCLoading(false);
    };

    const handleChangeDeliveryAddress = async (
        address: getDeliveryAddressesByCustomerId_deliveryAddresses,
        keepOpen = false
    ) => {
        setDeliveryAddress(address);
        setStore(address.fulfillmentLocations![0].fulfillmentStore!);
        setFulfillmentLocationId(Number(address.fulfillmentLocations![0].fulfillmentLocationId));

        await handleBasketContinuity(address, keepOpen);
    };

    const handleOnClick = async (address: getDeliveryAddressesByCustomerId_deliveryAddresses) => {
        setIsLoading(true);
        let isVerificationNeeded = false;
        const {addressOne, addressTwo, city, state, zip, firstName, lastName, phoneNumber, companyName, isVerified} =
            address;

        const shouldRunAddressValidation = shouldRunVerificationService(isAddressVerificationEnabled, isVerified);

        if (shouldRunAddressValidation) {
            const addressSuggestionRes = await fetchAddressSuggestions(address);
            const shouldUpdateDeliveryAddress = shouldUpdateDeliveryAddressService(addressSuggestionRes);
            const shouldRouteToConfirmAddress = shouldRouteToConfirmAddressService(addressSuggestionRes);

            if (shouldUpdateDeliveryAddress) {
                await updateDeliveryAddress(address);
            } else if (shouldRouteToConfirmAddress) {
                isVerificationNeeded = true;
                const userInputAddressDeliveryEligibility =
                    await isUserInputAddressEligibleForDelivery(addressSuggestionRes);
                const suggestions = await getAddressSuggestions(addressSuggestionRes);

                setIsVerified(isAddressVerified(addressSuggestionRes));
                setIsUserInputAddressEligibleForDelivery(userInputAddressDeliveryEligibility);
                setAddressSuggestion(suggestions);
                setUserInputAddress({
                    addressOne: addressOne.trim(),
                    addressTwo: addressTwo?.trim() || undefined,
                    city,
                    companyName: companyName || '',
                    firstName,
                    lastName: lastName || '',
                    phoneNumber: phoneNumber || '',
                    state: {
                        label: state,
                        value: state
                    },
                    zip
                });
                setView(ReservationViewType.VERIFY_ADDRESS);
            }
        }

        const activeFulfillmentLocations = filterDeliveryFulfillmentLocations(address);

        if (activeFulfillmentLocations && activeFulfillmentLocations.length > 1) {
            setDeliveryAddress(address);
            setDeliveryStores(activeFulfillmentLocations);
            setView(ReservationViewType.CHOOSE_DELIVERY_STORE);

            return;
        }

        if (!isVerificationNeeded) {
            await handleChangeDeliveryAddress(address);
        }

        setIsLoading(false);
    };

    const handleAddAddressButtonClick = () => {
        setView(ReservationViewType.ADD_ADDRESS);
    };

    const newAddressSaved = async () => {
        if (
            newAddressAddedStatus.status === AddressSavedStatus.SAVED ||
            (newAddressAddedStatus.status === AddressSavedStatus.UPDATED && deliveryAddresses)
        ) {
            const addressToSet = (deliveryAddresses?.deliveryAddresses ?? []).find(
                (add) => add.addressOne === newAddressAddedStatus.address.addressOne
            );

            if (addressToSet) {
                await handleChangeDeliveryAddress(
                    addressToSet,
                    newAddressAddedStatus.status === AddressSavedStatus.SAVED
                );
            }
        }
    };

    useEffect(() => {
        if (newAddressAddedStatus.status === AddressSavedStatus.SAVED) {
            refetch();
        }
    }, [newAddressAddedStatus, refetch]);

    useEffect(() => {
        newAddressSaved();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryAddresses, newAddressAddedStatus.status]);

    if (isBCLoading) {
        return <BasketContinuityLoadingSpinner />;
    }

    return (
        <ChangeAddressContainer>
            {newAddressAddedStatus.status === AddressSavedStatus.SAVED_NOT_ELIGIBLE && (
                <Alert status={AlertStatusType.ERROR_ALERT}>{addressSavedMessages.SAVED_NOT_ELIGIBLE}</Alert>
            )}
            {newAddressAddedStatus.status === AddressSavedStatus.SAVED && (
                <Alert status={AlertStatusType.SUCCESS_ALERT}>{addressSavedMessages.SAVED}</Alert>
            )}
            <div css="flex-grow: 2">
                {queryLoading || isLoading ? (
                    <SpinnerContainer>
                        <Spinner position="relative" />
                    </SpinnerContainer>
                ) : (
                    <>
                        {currentDeliveryAddress && (
                            <ReservationStepperStoreSelectionResult
                                address={currentDeliveryAddress.addressOne}
                                cityStateZip={getCityStateZip(currentDeliveryAddress)}
                                hideAvailabilityText
                                isCurrentAddress
                                isDrawer
                                name={fullName(currentDeliveryAddress)}
                                onClick={async () => handleOnClick(currentDeliveryAddress)}
                            />
                        )}
                        {(deliveryAddresses?.deliveryAddresses ?? [])
                            .filter(
                                (address) =>
                                    !isShopInStoreOnly(featureEnabled, address) &&
                                    address?.deliveryAddressId !== currentDeliveryAddress?.deliveryAddressId
                            )
                            .map((address) => (
                                <ReservationStepperStoreSelectionResult
                                    address={address.addressOne}
                                    cityStateZip={getCityStateZip(address)}
                                    hideAvailabilityText
                                    isDrawer
                                    key={address.deliveryAddressId}
                                    name={fullName(address)}
                                    onClick={async () => handleOnClick(address)}
                                />
                            ))}
                        <EligibleAddressText>{changeAddressMessages.ONLY_DISPLAY_ELIGIBLE}</EligibleAddressText>
                    </>
                )}
            </div>
            <Footer>
                <LinkButton onClick={handleAddAddressButtonClick} theme={themeObject}>
                    <StyledIconContainer>
                        <AddIcon color="currentColor" size="small" />
                    </StyledIconContainer>
                    {buttonText.addAddress}
                </LinkButton>
                <LinkButton
                    as="a"
                    href={ACCOUNT_ADDRESSES_PATH}
                    rel="noopener noreferrer"
                    target="_top"
                    theme={themeObject}
                >
                    <StyledIconContainer>
                        <SettingsIcon color="currentColor" size="small" />
                    </StyledIconContainer>
                    {buttonText.manageAddresses}
                </LinkButton>
            </Footer>
        </ChangeAddressContainer>
    );
};
