import queryString from 'query-string';
import {useQuery} from '@apollo/client';

import {useUserDetails} from 'client/context/user-details';
import {GetLocationByBusinessCodes} from 'autogen/GetLocationByBusinessCodes';
import {LEGACY_VERIFY_LEVELS} from 'client/enums/address-types';
import fetchRequest from 'client/services/fetch-service';
import {AddressFormData} from 'client/views/reservation-drawer/add-address/AddAddressUtils';
import {RadioOptions} from 'client/views/reservation-drawer/add-address/VerifyAddAddress';
import {getDeliveryAddressesByCustomerId as getDeliveryAddressesByCustomerIdFetch} from 'client/graphql/queries/delivery-address-queries';
import {
    getDeliveryAddressesByCustomerId,
    getDeliveryAddressesByCustomerIdVariables,
    getDeliveryAddressesByCustomerId_deliveryAddresses
} from 'autogen/getDeliveryAddressesByCustomerId';
import {LocationEcommerceStatus} from 'autogen/globalTypes';

import {
    serializeAddressData,
    fetchFulfillmentStoresEcommerceStatus,
    mapAddressSuggestionToAddressFormData,
    formatPhoneNumber
} from './address-validation-utils';

const {NONE, VERIFIED} = LEGACY_VERIFY_LEVELS;

export interface IAddressInput {
    addressOne: string;
    addressTwo: string | null | undefined;
    city: string;
    state: string;
    zip: string;
    companyName?: string | null;
    firstName?: string;
    isVerified?: boolean;
    lastName?: string | null;
    locationType?: string;
    phoneNumber?: string | null;
}

export interface IAddressSuggestionsAddress {
    addressOne: string;
    addressTwo: string | null;
    city: string;
    formattedAddress: string;
    fulfillmentLocations: {
        canFulfillAlcohol: boolean;
        crossDockId: number | null;
        deliveryProdivderNames: string[];
        fulfillmentLocationId: number;
        fulfillmentStoreId: number;
        fulfillmentType: string;
        locationName: string;
        requireSeparateAlcoholOrder: boolean;
        territoryId: number;
        territoryName: string;
    }[];
    latitude: number;
    longitude: number;
    state: string;
    zip: string;
}

export interface IInputAddress extends IAddressSuggestionsAddress {
    isVerified: boolean;
}

export interface IAddressSuggestionsResponse {
    addressSuggestions: {
        suggestions: IAddressSuggestionsAddress[];
        inputAddress: IInputAddress;
        verifyLevel: typeof NONE | typeof VERIFIED;
    }[];
}

export type TAddressSuggestionWithDeliveryEligibility = IAddressSuggestionsAddress & {isEligibleForDelivery: boolean};

export class FetchRequestError extends Error {
    public response?: Response;

    public constructor(message: string) {
        super(message);
        this.name = 'FetchRequestError';
    }
}

export const useAddressValidation = () => {
    const {customerId} = useUserDetails();
    const {data: deliveryAddresses} = useQuery<
        getDeliveryAddressesByCustomerId,
        getDeliveryAddressesByCustomerIdVariables
    >(getDeliveryAddressesByCustomerIdFetch, {
        errorPolicy: 'all',
        skip: !customerId,
        variables: {
            customerId: customerId as number
        }
    });

    const fetchAddressSuggestions = async (
        address: AddressFormData | getDeliveryAddressesByCustomerId_deliveryAddresses
    ) => {
        const addressInput = serializeAddressData(address);

        try {
            const res = await fetchRequest(
                `/aisles-online/api/search/address-suggestions?${queryString.stringify(addressInput)}`
            );

            const data: IAddressSuggestionsResponse = await res.json();

            return data;
        } catch (error) {
            console.error('Call fetch address suggestions failed.', {
                component: 'ChangeAddress',
                error
            });
        }
    };

    const createDeliveryAddress = async (
        address: IAddressInput
    ): Promise<{err: FetchRequestError | undefined; response: Response | undefined}> => {
        try {
            const response = await fetchRequest(`/aisles-online/api/customer/${customerId}/delivery-address`, {
                body: JSON.stringify({address}),
                method: 'POST'
            });

            return {
                err: undefined,
                response
            };
        } catch (error) {
            console.error(`POST /aisles-online/api/customer/${customerId}/delivery-address call failed.`, {
                component: 'VerifyAddAddress',
                error
            });

            return {
                err: error,
                response: undefined
            };
        }
    };

    const updateDeliveryAddress = async (
        address: getDeliveryAddressesByCustomerId_deliveryAddresses,
        isVerified = true
    ): Promise<{err: FetchRequestError | undefined; response: Response | undefined; payload: IAddressInput}> => {
        const {
            addressOne,
            addressTwo,
            city,
            state,
            zip,
            firstName,
            lastName,
            locationType,
            phoneNumber,
            companyName,
            deliveryAddressId
        } = address;
        const mappedAddress: IAddressInput = {
            addressOne: addressOne.trim(),
            addressTwo: addressTwo?.trim() || null,
            city,
            companyName: companyName || null,
            firstName,
            isVerified,
            lastName,
            locationType,
            phoneNumber: phoneNumber || null,
            state,
            zip
        };

        try {
            const response = await fetchRequest(
                `/aisles-online/api/customer/${customerId}/delivery-address/${deliveryAddressId}`,
                {
                    body: JSON.stringify({
                        ...mappedAddress
                    }),
                    method: 'PUT'
                }
            );

            return {
                err: undefined,
                payload: mappedAddress,
                response
            };
        } catch (error) {
            console.error('PUT delivery-address verification call failed.', {
                component: 'ChangeAddress',
                error
            });

            return {
                err: error,
                payload: mappedAddress,
                response: undefined
            };
        }
    };

    const hasEligibleLocation = (data: GetLocationByBusinessCodes | undefined): boolean => {
        if (!data || !data.locationsByBusinessCodes?.length) return false;

        return data.locationsByBusinessCodes.some(
            ({ecommerceStatus}) =>
                ecommerceStatus === LocationEcommerceStatus.ACTIVE ||
                ecommerceStatus === LocationEcommerceStatus.DELIVERY_ONLY
        );
    };

    const isUserInputAddressEligibleForDelivery = async (
        addressSuggestionsResponse: IAddressSuggestionsResponse | undefined
    ): Promise<boolean> => {
        if (
            !addressSuggestionsResponse ||
            !addressSuggestionsResponse.addressSuggestions[0].inputAddress ||
            !addressSuggestionsResponse.addressSuggestions[0].inputAddress.fulfillmentLocations.length
        )
            return false;

        const fulfillmentStoreIds =
            addressSuggestionsResponse.addressSuggestions[0].inputAddress.fulfillmentLocations.map(
                ({fulfillmentStoreId}) => String(fulfillmentStoreId)
            );

        const {data, error} = await fetchFulfillmentStoresEcommerceStatus(fulfillmentStoreIds);

        if (error) {
            console.error('Error fetching ecommerce status.', {
                component: 'ChangeAddress',
                error
            });

            return false;
        }

        return hasEligibleLocation(data);
    };

    const addDeliveryEligibilityToSuggestion = async (
        suggestion: IAddressSuggestionsAddress
    ): Promise<TAddressSuggestionWithDeliveryEligibility> => {
        const fulfillmentStoreIds = suggestion.fulfillmentLocations.map(({fulfillmentStoreId}) =>
            String(fulfillmentStoreId)
        );

        const {data, error} = await fetchFulfillmentStoresEcommerceStatus(fulfillmentStoreIds);

        const isEligibleForDelivery = !error && hasEligibleLocation(data);

        return {
            ...suggestion,
            isEligibleForDelivery
        };
    };

    const isAddressVerified = (addressSuggestionsResponse: IAddressSuggestionsResponse | undefined): boolean => {
        if (!addressSuggestionsResponse) return false;

        return addressSuggestionsResponse.addressSuggestions[0].verifyLevel === VERIFIED;
    };

    const getAddressSuggestions = async (
        addressSuggestionsResponse: IAddressSuggestionsResponse | undefined
    ): Promise<TAddressSuggestionWithDeliveryEligibility[]> => {
        if (!addressSuggestionsResponse) return [];

        const suggestionsWithDeliveryEligibility: TAddressSuggestionWithDeliveryEligibility[] = await Promise.all(
            addressSuggestionsResponse.addressSuggestions[0].suggestions.map(async (suggestion) =>
                addDeliveryEligibilityToSuggestion(suggestion)
            )
        );

        return suggestionsWithDeliveryEligibility;
    };

    const shouldUpdateDeliveryAddressService = (
        addressSuggestionsResponse: IAddressSuggestionsResponse | undefined
    ): boolean => {
        const addressSuggestion =
            addressSuggestionsResponse?.addressSuggestions && addressSuggestionsResponse?.addressSuggestions.length
                ? addressSuggestionsResponse.addressSuggestions[0]
                : null;

        if (addressSuggestion && addressSuggestion.verifyLevel === VERIFIED) {
            return true;
        }

        return false;
    };

    const shouldRouteToConfirmAddressService = (
        addressSuggestionsResponse: IAddressSuggestionsResponse | undefined
    ): boolean => {
        const addressSuggestion =
            addressSuggestionsResponse?.addressSuggestions && addressSuggestionsResponse?.addressSuggestions.length
                ? addressSuggestionsResponse.addressSuggestions[0]
                : null;

        if (addressSuggestion && addressSuggestion.verifyLevel !== VERIFIED) {
            return true;
        }

        return false;
    };

    const shouldRunVerificationService = (isFeatureToggleOn: boolean, isVerified: boolean | null): boolean =>
        isFeatureToggleOn && !isVerified;

    const buildDeliveryAddressPayload = (
        radioOption: RadioOptions,
        isVerified: boolean,
        addressSuggestion: IAddressSuggestionsAddress,
        userInputAddress: AddressFormData
    ): IAddressInput => {
        const payload: AddressFormData =
            radioOption === 'suggested'
                ? mapAddressSuggestionToAddressFormData(addressSuggestion, userInputAddress)
                : userInputAddress;

        return {
            ...payload,
            addressTwo: payload.addressTwo || null,
            companyName: payload.companyName || null,
            isVerified,
            locationType: 'Residence',
            phoneNumber: formatPhoneNumber(userInputAddress),
            state: payload.state.value
        };
    };

    const isNewAddress = (addressData: AddressFormData): boolean => {
        if (!deliveryAddresses?.deliveryAddresses) return true;

        const duplicateAddress = deliveryAddresses?.deliveryAddresses.filter((address) => {
            return address.addressOne === addressData.addressOne && address.zip === addressData.zip;
        });

        return duplicateAddress.length === 0;
    };

    const getDeliveryAddressForUpdate = (
        addressData: AddressFormData
    ): getDeliveryAddressesByCustomerId_deliveryAddresses | undefined => {
        if (!deliveryAddresses?.deliveryAddresses) return undefined;

        return deliveryAddresses.deliveryAddresses.find(
            (address) => address.addressOne === addressData.addressOne && address.zip === addressData.zip
        );
    };

    return {
        buildDeliveryAddressPayload,
        createDeliveryAddress,
        fetchAddressSuggestions,
        getAddressSuggestions,
        getDeliveryAddressForUpdate,
        isAddressVerified,
        isNewAddress,
        isUserInputAddressEligibleForDelivery,
        shouldRouteToConfirmAddressService,
        shouldRunVerificationService,
        shouldUpdateDeliveryAddressService,
        updateDeliveryAddress
    };
};
