import {FC, Dispatch, SetStateAction, useState, useEffect, PropsWithChildren} from 'react';
import StoreSelection from '@hy-vee/web-store-select/lib/components/store-selection/store-selection';
import getConfig from 'next/config';
import {useQuery, useMutation} from '@apollo/client';
import Spinner from '@hy-vee/web-core/lib/components/loading-spinner';
import styled from 'styled-components';
import {Label} from '@hy-vee/design-system';
import {spacing, colors} from '@hy-vee/themes';
import {setLocationToCookie, ILocation} from '@hy-vee/user-location';

import {getCustomerData} from 'client/graphql/queries/customer-and-feature-queries';
import {getCityStateZip, getPickupLocationsWithMilesAway} from 'client/utils/view-helpers/address-view-helpers';
import {GetActivePickupLocations, GetActivePickupLocations_pickupLocations} from 'autogen/GetActivePickupLocations';
import {getActivePickupLocationsQuery} from 'client/graphql/queries/pickup-location-queries';
import {
    GetCartsForReservationDetails_carts_pickupLocation,
    GetCartsForReservationDetails_carts_store
} from 'autogen/GetCartsForReservationDetails';
import {locationIsDeliveryOnly, locationIsIneligible} from 'client/utils/view-helpers/fulfillment-view-helpers';
import {getLocationEcommerceStatus} from 'client/graphql/queries/location-queries';
import {GetLocationEcommerceStatus, GetLocationEcommerceStatusVariables} from 'autogen/GetLocationEcommerceStatus';
import {getBasketContinuitySummaryDrawer} from 'client/services/basket-continuity-service';
import {BasketContinuityCartInput, FulfillmentType} from 'autogen/globalTypes';
import {useReservationDrawer} from 'client/hooks/use-reservation-drawer';
import {getDeliveryAddressesByCustomerId_deliveryAddresses} from 'autogen/getDeliveryAddressesByCustomerId';
import {updatePreferredStoreMutation} from 'client/graphql/mutations/preferred-store-mutations';
import {UpdatePreferredStore, UpdatePreferredStoreVariables} from 'autogen/UpdatePreferredStore';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';

import {BasketContinuityLoadingSpinner} from '../components/reservation-drawer-bc-loading';
import ReservationStepperStoreSelectionResult from '../components/reservation-stepper/reservation-stepper-store-selection-result';

import {ReservationViewType} from '.';

const SpinnerContainer = styled.div`
    padding-top: 2rem;
    display: block;
    height: 70px;
    position: relative;
    width: 70px;
    margin: 0 auto;
`;

export interface IReservationDrawerView {
    basketContinuityInput: BasketContinuityCartInput;
    cartId: string | null;
    customerId: number | undefined;
    isAuthenticated: boolean;
    setActiveView: Dispatch<SetStateAction<ReservationViewType>>;
    setBasketContinuityInput: Dispatch<SetStateAction<BasketContinuityCartInput | any>>;
    setDeliveryAddress: Dispatch<SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses | null>>;
    pickupLocation: GetCartsForReservationDetails_carts_pickupLocation | null;
    setPendingPickupLocation: Dispatch<SetStateAction<GetActivePickupLocations_pickupLocations | null>>;
    setSelectedPickupLocation: Dispatch<SetStateAction<GetCartsForReservationDetails_carts_pickupLocation>>;
    setFulfillmentLocationId: Dispatch<SetStateAction<number | null>>;
    setFulfillmentType: Dispatch<SetStateAction<String>>;
    closeDrawer: () => void;
    setStore: Dispatch<SetStateAction<GetCartsForReservationDetails_carts_store>>;
    store: GetCartsForReservationDetails_carts_store | null;
    storeFromCookie: ILocation | undefined;
}

export const formatLocationForCookie = ({
    address,
    city,
    latitude,
    longitude,
    name,
    state,
    zip,
    fulfillmentStore
}: GetActivePickupLocations_pickupLocations): ILocation => ({
    address,
    city,
    latitude: Number(latitude),
    longitude: Number(longitude),
    name,
    state,
    storeId: fulfillmentStore?.storeId ?? '',
    zip
});

const ChangeStore: FC<PropsWithChildren<PropsWithChildren<IReservationDrawerView>>> = ({
    basketContinuityInput,
    cartId,
    customerId,
    isAuthenticated,
    setActiveView,
    setDeliveryAddress,
    pickupLocation,
    setSelectedPickupLocation,
    setFulfillmentLocationId,
    setFulfillmentType,
    closeDrawer,
    setBasketContinuityInput,
    setPendingPickupLocation,
    setStore,
    store,
    storeFromCookie
}) => {
    const {publicRuntimeConfig} = getConfig();
    const GOOGLE_MAP_API_KEY = publicRuntimeConfig.googleMapsClientApiKey;
    const SEARCH_RADIUS = 35;
    const [latitude, setLatitude] = useState('0.0');
    const [longitude, setlongitude] = useState('0.0');
    const [isBCLoading, setIsBCLoading] = useState(false);
    const {cart, isSelectAPreferredStore} = useReservationDrawer();

    const [callMutation] = useMutation<UpdatePreferredStore, UpdatePreferredStoreVariables>(
        updatePreferredStoreMutation,
        {
            refetchQueries: [getCustomerData]
        }
    );

    const {data, loading} = useQuery<GetActivePickupLocations>(getActivePickupLocationsQuery, {
        errorPolicy: 'all',
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE
        }
    });

    const {data: locationData, loading: locationLoading} = useQuery<
        GetLocationEcommerceStatus,
        GetLocationEcommerceStatusVariables
    >(getLocationEcommerceStatus, {
        skip: !(pickupLocation || store),
        variables: {
            businessCode:
                (pickupLocation?.pickupStoreId ? pickupLocation.pickupStoreId.toString() : '') || (store?.storeId ?? '')
        }
    });

    const pickupLocations = data?.pickupLocations
        ? getPickupLocationsWithMilesAway(data.pickupLocations, latitude, longitude)
        : [];
    const dollarFreshPickupLocations = data?.pickupLocations
        ? pickupLocations.filter((location) => location.fulfillmentStoreLocation?.format.code === 'DOLLAR_FRESH')
        : [];

    const isDollarFreshBrandSite = window.location.host.includes('dollar-fresh');

    const filteredPickupLocations = (isDollarFreshBrandSite ? dollarFreshPickupLocations : pickupLocations)
        .filter((location) => {
            if (!isAuthenticated) {
                return (
                    location.milesAway <= SEARCH_RADIUS &&
                    !location.hasPasscode &&
                    location.fulfillmentType === 'PICKUP' &&
                    (location.pickupStoreId ?? location.fulfillmentStoreId) !== Number(storeFromCookie?.storeId)
                );
            }

            if (cart?.fulfillmentType === FulfillmentType.DELIVERY) {
                return location.milesAway <= SEARCH_RADIUS;
            }

            return (
                location.milesAway <= SEARCH_RADIUS &&
                (location.pickupStoreId ?? location.fulfillmentStoreId) !==
                    (pickupLocation?.pickupStoreId || Number(store?.storeId))
            );
        })
        .sort((pickupLocationA, pickupLocationB) => pickupLocationA.milesAway - pickupLocationB.milesAway);

    const handleClick = async (newPickupLocation?: GetActivePickupLocations_pickupLocations) => {
        if (!newPickupLocation) {
            closeDrawer();

            return;
        }

        if (!isAuthenticated) {
            setLocationToCookie(formatLocationForCookie(newPickupLocation));
            closeDrawer();

            // We ensure the browser reloads as it can be rendered in an iframe
            window.top?.location.reload();

            return;
        }

        const selectAStoreView = Boolean(isSelectAPreferredStore && newPickupLocation.fulfillmentStore?.storeId);

        if (newPickupLocation.hasPasscode) {
            setPendingPickupLocation(newPickupLocation);

            return setActiveView(ReservationViewType.LOCATION_OPENING_SOON);
        }

        setFulfillmentLocationId(newPickupLocation.fulfillmentLocationId);
        setFulfillmentType(newPickupLocation.fulfillmentType);
        setSelectedPickupLocation(newPickupLocation);

        if (newPickupLocation.fulfillmentStore) {
            setStore(newPickupLocation.fulfillmentStore);
        }

        setIsBCLoading(true);
        setDeliveryAddress(null);
        await getBasketContinuitySummaryDrawer({
            ...basketContinuityInput,
            cartId,
            closeDrawer,
            customerId,
            deliveryAddressId: null,
            fulfillmentLocationId: Number(newPickupLocation.fulfillmentLocationId),
            fulfillmentType: newPickupLocation.fulfillmentType,
            keepOpen: selectAStoreView,
            pickupLocationId: Number(newPickupLocation.pickupLocationId),
            setActiveView,
            setBasketContinuityInput,
            storeId: Number(newPickupLocation.fulfillmentStoreId)
        });

        if (selectAStoreView) {
            await callMutation({
                variables: {
                    preferredStoreId: Number(newPickupLocation.fulfillmentStore?.storeId)
                }
            });

            closeDrawer();
        }

        setIsBCLoading(false);
    };

    useEffect(() => {
        if (pickupLocation) {
            setLatitude(pickupLocation.latitude);
            setlongitude(pickupLocation.longitude);
        } else if (store) {
            setLatitude(store.latitude ? store.latitude.toString() : '0.0');
            setlongitude(store.longitude ? store.longitude.toString() : '0.0');
        } else if (storeFromCookie) {
            setLatitude(storeFromCookie.latitude ? storeFromCookie.latitude.toString() : '0.0');
            setlongitude(storeFromCookie.longitude ? storeFromCookie.longitude.toString() : '0.0');
        }
    }, [pickupLocation, store, setLatitude, setlongitude, storeFromCookie]);

    if (isBCLoading) {
        return <BasketContinuityLoadingSpinner />;
    }

    return (
        <>
            {(pickupLocation || store) &&
                cart?.fulfillmentType !== FulfillmentType.DELIVERY &&
                !isSelectAPreferredStore &&
                isAuthenticated && (
                    <ReservationStepperStoreSelectionResult
                        address={pickupLocation ? pickupLocation.address : store!.address}
                        cityStateZip={getCityStateZip(pickupLocation || store)}
                        css={`
                            border-bottom: ${spacing.extraSmall} solid ${colors.grey[100]};
                        `}
                        isCurrentAddress
                        isDeliveryOnly={locationIsDeliveryOnly(
                            locationData?.locationsByBusinessCode?.[0]?.ecommerceStatus
                        )}
                        isDrawer
                        name={pickupLocation ? pickupLocation.name : store!.name}
                        onClick={async () => handleClick()}
                        shopInStoreOnly={
                            !!locationData?.locationsByBusinessCode?.length &&
                            locationIsIneligible(locationData?.locationsByBusinessCode[0].ecommerceStatus)
                        }
                    />
                )}

            {!isAuthenticated && storeFromCookie && (
                <ReservationStepperStoreSelectionResult
                    address={storeFromCookie.address}
                    cityStateZip={getCityStateZip(storeFromCookie)}
                    css={`
                        border-bottom: ${spacing.extraSmall} solid ${colors.grey[100]};
                    `}
                    isCurrentAddress
                    isDeliveryOnly={locationIsDeliveryOnly(locationData?.locationsByBusinessCode?.[0]?.ecommerceStatus)}
                    isDrawer
                    name={storeFromCookie.name}
                    onClick={async () => handleClick()}
                    shopInStoreOnly={
                        !!locationData?.locationsByBusinessCode?.length &&
                        locationIsIneligible(locationData?.locationsByBusinessCode[0].ecommerceStatus)
                    }
                />
            )}

            <div
                style={{
                    padding:
                        (!pickupLocation && !store) ||
                        isSelectAPreferredStore ||
                        cart?.fulfillmentType === FulfillmentType.DELIVERY
                            ? '0 16px'
                            : '16px'
                }}
            >
                <Label
                    htmlFor={'location-input'}
                    required
                    style={{
                        display: 'block',
                        margin: '16px 0px 8px'
                    }}
                >
                    Find a store
                </Label>
                <StoreSelection
                    mapsApiKey={GOOGLE_MAP_API_KEY}
                    onLocationSelect={({latitude: lat, longitude: long}) => {
                        setLatitude(lat);
                        setlongitude(long);
                    }}
                    placeholder={'City, State, or Zip Code'}
                />
            </div>
            {loading || locationLoading ? (
                <SpinnerContainer>
                    <Spinner position="relative" />
                </SpinnerContainer>
            ) : (
                filteredPickupLocations.map((location) => (
                    <ReservationStepperStoreSelectionResult
                        address={location.address}
                        cityStateZip={getCityStateZip(location)}
                        hasPasscode={location.hasPasscode}
                        isDeliveryOnly={locationIsDeliveryOnly(location.fulfillmentStoreLocation?.ecommerceStatus)}
                        isDrawer
                        milesAway={location.milesAway}
                        name={location.name}
                        onClick={async () => handleClick(location)}
                        shopInStoreOnly={locationIsIneligible(location.fulfillmentStoreLocation?.ecommerceStatus)}
                        showMiles
                    />
                ))
            )}
        </>
    );
};

export default ChangeStore;
